@font-face {
    font-family: "Lato";
    src: url('../../static/fonts/Lato-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

.toolbar {
    font-family: 'Lato', sans-serif;
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    padding: 15px 25px;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 1;
}

.toolbar a {
    color: #6F7E84;
    font-size: 20px;
    font-weight: 300;
    outline: none;
    text-decoration: none;
}

.toolbar a.red {
    color: #B52D20;
}

.toolbar .spacer {
    flex: 1;
}

.toolbar .switcherContainer {
    position: absolute;
    right: 10px;
    top: 80px;
    display: flex;
    height: 50px;
    max-width: 300px;
    margin-left: -25px;
    z-index: 2;
    width: 100%;
    justify-content: flex-end;


    @media screen and (max-width: 570px) {
        position: fixed;
        top: auto;
        bottom: 20px;
    }
}

.toolbar .switcher {
    width: 230px;
    display: flex;
    justify-content: center;
    color: #6F7E84;
    align-items: center;
    padding: 20px;
    padding-left: 15px;
    padding-right: 35px;
    background-color: white;
    border-radius: 32px;
    border: 1px solid #00000029;
    box-shadow: 6px 3px 6px #00000029;
}

.toolbar .navigation {
    display: flex;
    width: 100%;
    align-items: center;
}

.toolbar .logo {
    margin-left: 10px;
    display: flex;
    align-items: center;
    color: #6F7E84;
}


.toolbar .navigationItems ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar .navigationItems ul li {
    padding: 10px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    margin-left: 10px;
    margin-right: 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
}

input+.slider {
    background-color: #B52D20;
}

input:focus+.slider {
    box-shadow: 0 0 1px #B52D20;
}

input:checked+.slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.selectedTypeLabel {
    font-weight: bolder;
}

.typeLabel {
    display: inline-block;
    max-width: 50px;
    width: 50px;
}

@media (max-width: 1000px) {
    .toolbar .navigationItems {
        display: none;
    }

    .toolbar .logo {
        display: none;
    }
}