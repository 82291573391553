.container
{
    flex-shrink: 0;
    flex-grow: 1;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    padding-left: calc(55px);
    padding-right: calc(55px);
    padding-bottom: 20px;

}