.toggleButton {
    font-family: 'Lato';
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 16px;
    width: 24px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
}

.toggleButton:focus {
    outline: none;
}

.toggleButton .toggleButtonLine {
    width: 100%;
    height: 2px;
    color: #6F7E84;
    background: #6F7E84;
}

.toggleButton img {
    width: 24px;
    height: 16px;
}