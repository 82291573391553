.loading {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-gray-dark);

    svg {
        width: 48px;
        height: 60px;
    }
}
