html {
    -webkit-text-size-adjust: 100%;
    height: 100%;
    font: var(--text-regular);
    color: var(--color-gray);
}

body {
    margin: 0;
    height: 100%;
    background-color: var(--color-white);
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    font: inherit;
}

button {
    box-shadow: none;
    border: none;
    background-color: var(--color-none);
    text-transform: none;
    outline: 0;
    cursor: pointer;
    font: inherit;
    letter-spacing: inherit;

    &:disabled {
        cursor: default;
    }
}

.page-content {
    padding-top: 64px;
}