.root {

   .innerLink {
      display: block;
      color: inherit;
      text-decoration: none;
      box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 2px 4px;
      border-radius: 3px;
      background: white;
      overflow: hidden;
      position: static;
      height: 100%;
   }

   .block {
      cursor: pointer;
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
   }

   .imgBlock {
      height: 125px;
      position: relative;
      overflow: hidden;

     img
     {
      display: block;
      object-fit: cover;
      border-radius: 1px 1px 0px 0px;
      width: 100%;
      height: 100%;
      object-position: center 50%;
     }

     .line {
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      border-bottom: 1px solid rgba(55, 53, 47, 0.09);
     }
   }

   .titleBlock {
      width: 100%;
      padding: 8px 10px 10px;

      .title {
         max-width: 100%;
         width: auto;
         white-space: nowrap;
         word-break: break-word;
         font-size: 14px;
         line-height: 1.5;
         min-height: 21px;
         font-weight: 500;
         overflow: hidden;
         text-overflow: ellipsis;
      }
   }

}