.root {
   width: 100%;
   align-self: center;
   margin-top: 2px;
   margin-bottom: 1px;

   .header {
      min-height: 42px;
      flex-shrink: 0;
      overflow: visible;
   }

   .flagsContainer
   {
      display: grid;
      position: relative;
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      grid-auto-rows: 1fr;
      gap: 16px;
      border-top: 1px solid rgb(223, 223, 222);
      padding-top: 16px;
      padding-bottom: 4px;
   }
}